/**
 * @file Login
 */

import React from 'react';
import '../../scss/app.scss';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.identity = props.identity;

    this.state = {
      buttonClass: null,
      email: '',
      password: '',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const { target } = event;
    const { value } = target;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({
      buttonClass: 'loading',
    });

    const {
      email,
      password,
    } = this.state;

    /* eslint no-underscore-dangle: ["error", { "allow": ["_goTrueInstance"] }] */
    this.identity._goTrueInstance.login(
      email,
      password, true,
    )
      .then(() => {
        setTimeout(() => {
          setTimeout(() => {
            document.location.href = '/app/dashboard';
          }, 2000);
        }, 2000);
      })
      .catch(() => {});
  }

  render() {
    const {
      buttonClass,
      email,
      password,
    } = this.state;

    return (
      <form
        className="identity-form"
        onSubmit={this.handleSubmit}
        action="/"
      >

        <div className="logo logo--login" />

        <input
          name="email"
          type="email"
          placeholder="Email"
          value={email}
          onChange={this.handleInputChange}
          className="input"
          autoFocus
          required
        />

        <input
          name="password"
          type="password"
          placeholder="Password"
          value={password}
          onChange={this.handleInputChange}
          className="input"
          required
        />

        <button
          type="submit"
          className={`button button--expanded button--primary ${buttonClass ? `button--${buttonClass}` : null}`}
        >
          Login
        </button>

      </form>
    );
  }
}

export default Login;
