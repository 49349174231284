/**
 * @file App
 */

import React from 'react';
import { Router } from '@reach/router';
import PrivateRoute from '../components/PrivateRoute';
import Login from '../components/Login';
import routes from '../config/routes';

const App = () => (
  <Router basepath="/app">
    {routes.main.map((route) => (
      <PrivateRoute path={route.path} component={route.component} key={route.name} />
    ))}
    {routes.docs.map((route) => (
      <PrivateRoute path={route.path} component={route.component} key={route.name} />
    ))}
    <Login path="/login" />
  </Router>
);

export default App;
