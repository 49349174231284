// eslint-disable jsx-props-no-spreading

/**
 * @file Private Route
 */

import React from 'react';
import { navigate } from 'gatsby';
import { useIdentityContext } from 'react-netlify-identity-widget';

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const identity = useIdentityContext();
  const isLoggedIn = identity && identity.isLoggedIn;

  if (!isLoggedIn) {
    navigate('/');
    return null;
  }

  return <Component {...rest} />;
};

export default PrivateRoute;
